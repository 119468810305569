import styled from 'styled-components';
import {StyledProps} from './typings';
import colors from '../../theme/constants/colors';
import pxToRem from '../../theme/helpers/pxToRem';
import typography from '@/theme/typography';

const localZindex = {
  label: 10,
};

const labelColor = (flagType) => {
  switch (flagType) {
    case 'green':
      return {
        fold: colors.darkGreen,
        main: colors.green100,
        font: colors.obsidian,
      };

    case 'red':
      return {
        fold: colors.darkRed,
        main: colors.red100,
        font: colors.white,
      };

    case 'yellow':
      return {
        fold: colors.darkYellow,
        main: colors.yellow100,
        font: colors.obsidian,
      };

    case 'blue':
      return {
        fold: colors.darkBlue,
        main: colors.skyBlue100,
        font: colors.obsidian,
      };

    case 'dark':
      return {
        fold: colors.obsidian,
        main: colors.darkBlue100,
        font: colors.white,
      };

    default:
      return {
        fold: colors.darkRed,
        main: colors.red200,
        font: colors.white,
      };
  }
};

const StyledRibbonLabel = styled.span<StyledProps>`
  position: absolute;
  right: -7px;
  top: 24px;
  padding: 0 8px;
  color: ${(props) => labelColor(props.$color).font};
  background: ${(props) => labelColor(props.$color).main};
  ${typography.Subtitle2}
  line-height: ${pxToRem(32)};
  white-space: nowrap;
  min-width: fit-content;
  text-transform: uppercase;
  height: 30px;
  box-shadow: -4px 3px 4px 0px rgba(0, 0, 0, 0.12), -2px 11px 17px -8px rgba(0, 0, 0, 0.3),
    -10px 6px 21px -1px rgba(0, 0, 0, 0.14);
  pointer-events: none;
  z-index: ${localZindex.label};

  &::before {
    content: '';
    position: absolute;
    font-size: 0;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-left: 7px solid ${(props) => labelColor(props.$color).fold};
    top: -5px;
    right: 0;
  }

  &::after {
    content: '';
    position: absolute;
    font-size: 0;
    width: 0;
    height: 0;
    border-radius: 2px 0 0 2px;
    top: 0;
    left: -10px;
    border-top: 15px solid ${(props) => labelColor(props.$color).main};
    border-bottom: 15px solid ${(props) => labelColor(props.$color).main};
    border-left: 10px solid transparent;
  }
`;

export default StyledRibbonLabel;
