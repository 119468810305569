import styled from 'styled-components';

import type {
  StyledCardGridListProps,
  StyledOutlinedLinksListProps,
  StyledBrandListItemProps,
  StyledABTestingContainerProps,
} from './typings';

import breakpoints from '@/theme/constants/breakpoints';
import typography from '@/theme/typography';
import {sizePx} from '@/theme/helpers/size';
import StyledTextLink from '@/atoms/Links/Text/styles';
import {StyledSearch} from '@/molecules/Search/styles';
import {StyledOutlinedLink} from '@/atoms/Links/Outlined/styles';
import {StyledPage, StyledColorBlock} from '../styles';
import scrollbar from '@/theme/elements/scrollbar';
import colors from '@/theme/constants/colors';

export const StyledHomepage = styled(StyledPage)`
  h1 {
    ${typography.Section}
    margin-bottom: ${sizePx(16)};
    text-align: center;

    @media only screen and ${breakpoints.device.l} {
      margin-bottom: ${sizePx(20)};
    }
  }
`;

export const StyledJoinMailingListContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  column-gap: ${sizePx(32)};
  row-gap: ${sizePx(12)};
  & button {
    &:focus {
      outline: revert;
    }
  }
  & h2 {
    ${typography.Section}
  }

  @media only screen and ${breakpoints.device.m} {
    flex-direction: row;

    & h2 {
      padding-top: ${sizePx(2)};
    }
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: ${sizePx(4)};

  h2 {
    ${typography.Section}
  }

  ${StyledTextLink} {
    margin-left: auto;
  }
`;

export const StyledTopUspContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: ${sizePx(16)} ${sizePx(8)};
  align-items: start;
  justify-items: center;

  @media only screen and ${breakpoints.device.m} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
`;

export const StyledCardGridList = styled.ul<StyledCardGridListProps>`
  width: 100%;
  box-sizing: content-box;
  gap: ${sizePx(8)};
  display: flex;
  align-self: flex-start;
  overflow-x: scroll;
  margin-left: -${sizePx(8)};
  margin-bottom: -${sizePx(8)};
  padding: ${sizePx(8)};
  list-style: none;
  ${scrollbar.hide}

  li > div {
    width: 300px;
    flex-shrink: 0;
  }

  @media only screen and ${breakpoints.device.m} {
    gap: ${sizePx(12)};
    margin-top: ${sizePx(2)};
  }

  @media only screen and ${breakpoints.device.l} {
    align-self: unset;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: repeat(${(props) => props.$desktopRows ?? 1}, 1fr);
    padding: 0;
    margin-top: ${sizePx(12)};
    margin-bottom: unset;
    margin-left: 0;
    overflow: visible;

    li > div {
      width: initial;
    }
  }
`;

export const StyledDiscoverArtistContainer = styled.div`
  ${StyledHeader} {
    align-items: flex-start;
    flex-direction: column;

    @media only screen and ${breakpoints.device.m} {
      display: grid;
      grid-template-columns: 1fr ${sizePx(121)};
      grid-template-rows: repeat(2, auto);
      grid-template-areas: 'header header' 'subtitle search';
      align-items: end;
      column-gap: ${sizePx(16)};
    }

    @media only screen and ${breakpoints.device.l} {
      grid-template-columns: 1fr ${sizePx(106)};
    }

    h2 {
      grid-area: header;
    }

    p {
      grid-area: subtitle;
    }

    ${StyledSearch} {
      grid-area: search;
    }
  }
`;

export const StyledOutlinedLinksList = styled.ul<StyledOutlinedLinksListProps>`
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  gap: ${sizePx(8)};
  overflow-x: scroll;
  box-sizing: content-box;
  padding: ${sizePx(1)} ${sizePx(8)};
  margin-left: -${sizePx(8)};
  margin-bottom: -${sizePx(1)};
  margin-top: ${(props) => (props.$noHeader ? -sizePx(1) : sizePx(7))};
  margin-right: auto;
  ${scrollbar.hide}

  @media only screen and ${breakpoints.device.m} {
    margin-top: ${(props) => (props.$noHeader ? -sizePx(1) : sizePx(9))};
  }

  @media only screen and ${breakpoints.device.l} {
    justify-content: ${(props) => props.$desktopJustify ?? 'start'};
    flex-wrap: wrap;
    overflow-x: unset;
    padding: 0;
    margin: 0;
    margin-top: ${(props) => (props.$noHeader ? 0 : sizePx(12))};
  }
`;

export const StyledShopByPriceList = styled.ul`
  width: 100%;
  margin: ${sizePx(8)} 0 0;
  padding: 0;
  overflow: visible;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: ${sizePx(8)};

  @media only screen and (max-width: calc(${breakpoints.size.m} - 1px)) {
    ${StyledOutlinedLink} {
      padding: 0;
      max-width: 100%;
    }
  }
  @media only screen and ${breakpoints.device.s} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and ${breakpoints.device.m} {
    display: flex;
    margin: ${sizePx(10)} 0 0;
  }

  @media only screen and ${breakpoints.device.l} {
    margin: ${sizePx(12)} 0 0;
  }
`;

export const StyledJointSectionsLayout = styled(StyledColorBlock).attrs({as: 'div'})`
  section {
    padding: 0;
    overflow: visible;

    &:not(:first-child) {
      margin-top: ${sizePx(32)};
    }
  }
`;

export const StyledBrandList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(1, 40px);
  align-content: center;
  gap: ${sizePx(4)};
`;

const scale = {
  bbc: 1,
  fastCompany: 0.8,
  bloomberg: 0.84,
  sundayTimes: 1,
  cnn: 1,
};

export const StyledBrandListItem = styled.li<StyledBrandListItemProps>`
  & > figure {
    object-fit: contain;
    width: 100%;
    height: 100%;
    background-color: white;
  }

  & > figure > img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    transform: scale(${(props) => scale[props.$id] ?? 1});
  }
`;

export const StyledBrandSection = styled(StyledColorBlock)`
  display: none;

  @media only screen and ${breakpoints.device.l} {
    display: flex;
  }
`;

export const StyledCustomerReviewsContainer = styled.div`
  ${StyledHeader} {
    margin-bottom: ${sizePx(12)};
    justify-content: center;
  }
`;

export const StyledABTestingContainer = styled.div<StyledABTestingContainerProps>`
  display: flex;
  flex-direction: column;

  & > section:nth-child(odd) {
    background: ${(props) =>
      props.$abTest === 'a' && props.$featuredArtworksLoaded ? colors.grey25 : colors.white};
    order: ${(props) => (props.$abTest === 'b' ? 2 : 1)};
  }

  & > section:nth-child(even) {
    background: ${(props) => (props.$abTest === 'a' ? colors.white : colors.grey25)};
    order: ${(props) => (props.$abTest === 'b' ? 1 : 2)};
  }
`;
