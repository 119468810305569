'use client';

import React, {useRef} from 'react';

import type {JoinMailingListProps as Props} from './typings';

import CtaButton from '@/atoms/Buttons/CTA/CtaButton';
import SubmitButton from '@/atoms/Buttons/Submit/SubmitButton';
import {useSubmitEmail} from '@/hooks/use-submit-email/use-submit-email';
import Modal from '@/molecules/Modal/Modal';
import Textfield from '@/molecules/Textfield/Textfield';
import {
  StyledJoinMailingListForm,
  StyledJoinMailingListFormContainer,
  StyledSuccessMessageContainer,
} from './styles';

export const formHelperText = {
  emailInput:
    'Please enter a valid email address, that you would like to receive our newsletter on.',
  submitSuccess:
    "Thank you! You're now subscribed to Artfinder's newsletter. You can close this dialog by clicking the 'OK' button or outside this dialog window.",
};

export const SuccessDialogContent = ({onClose}: {onClose: () => void}) => (
  <StyledSuccessMessageContainer
    aria-describedby="submit-success-help"
    aria-label="success message"
  >
    <span id="submit-success-help" className="sr-only">
      {formHelperText.submitSuccess}
    </span>
    <p>Thank you!</p>
    <p>
      You're now subscribed to Artfinder's newsletter. Look out for our beautifully curated emails
      and add us to your address book so we don't get lost in your spam folder.
    </p>
    <CtaButton onClick={onClose}>OK</CtaButton>
  </StyledSuccessMessageContainer>
);

/**
 * @description The `Join Mailing List` form, that allows the user to subscribe to the newsletter
 * @param {Props} props function that makes api call submitting the email
 * @return {JSX.Element} The JoinMailingList form jsx element
 */
const JoinMailingList = ({onSubmit}: Props): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const {status, errors, handleReset, submitForm} = useSubmitEmail({
    inputRef,
    onSubmit,
  });

  const handleSubmit = (formEvent: React.FormEvent<HTMLFormElement>) => {
    formEvent.preventDefault();
    if (status === 'loading') return;
    submitForm();
  };

  return (
    <>
      <StyledJoinMailingListForm aria-label="form" onSubmit={handleSubmit} noValidate>
        <StyledJoinMailingListFormContainer $showHelper={errors.length > 0}>
          <fieldset aria-label="email input field">
            <Textfield
              ref={inputRef}
              id="join-mailing-list-email"
              label="Email"
              type="email"
              placeholder="Enter email address"
              autocomplete="off"
              required={true}
              helper={errors.length > 0 ? errors[0] : formHelperText.emailInput}
              aria-describedby={errors.length > 0 ? 'email-input-error' : 'email-input-help'}
              error={errors.length > 0}
              disabled={status === 'loading'}
            />
          </fieldset>
          <SubmitButton disabled={status === 'loading'}>Sign up</SubmitButton>
        </StyledJoinMailingListFormContainer>
      </StyledJoinMailingListForm>
      {status === 'success' && (
        <Modal onClose={handleReset}>
          <SuccessDialogContent onClose={handleReset} />
        </Modal>
      )}
    </>
  );
};

export default JoinMailingList;
