import constantsFactory from '@/utils/constants';
import paramsHelper from '@/utils/paramsHelper';
import {fetchJson} from '@/utils/fetch';
import {ProductProps} from '@/molecules/Cards/Product/typings';

export const getFeaturedArtworks = async (
  count: number | undefined = 4,
): Promise<ProductProps[] | null> => {
  const {API} = constantsFactory();
  const params = {
    limit: count,
  };
  const query = paramsHelper(params).objectToQueryString();
  const url = `${process.env.HOST}${API.PRODUCT_SEARCH_API}${query}`;

  return fetchJson(url)
    .then((response) => {
      return response?.results || null;
    })
    .catch((error) => {
      console.log('WARNING. Featured artworks fetch: ', error);
      return null;
    });
};
