import {useState, useEffect} from 'react';

import {ABTestDataHookProps as Props} from './typings';

/**
 * @description hook to fetch A/B test data asynchronously
 * @example
 * const {data, isLoading, error} = useAbTestData({
 *   enabled: true,
 *   asyncFn: async () => {
 *     const response = await fetch('https://api.example.com/ab-test');
 *     return response.json();
 *   },
 *   initialData: null,
 * });
 *
 * @param {Object} props
 * @param {boolean} props.enabled flag to enable fetching data
 * @param {Function} props.asyncFn async function to fetch data
 * @param {Object|undefined} props.initialData initial data to set(optional, default value to `null`)
 * @return {Object} data, isLoading, error
 */
const useAbTestData = <T>({enabled, asyncFn, initialData = null}: Props<T>) => {
  const [data, setData] = useState<T | null>(() => initialData);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!enabled) return;
    let isMounted = true;

    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const fetchedData = await asyncFn();
        if (isMounted) {
          setData(fetchedData);
          setIsLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          setError('Failed to fetch AB test data');
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [enabled, asyncFn]);

  return {data, isLoading, error};
};

export default useAbTestData;
