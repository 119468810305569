import {useState} from 'react';
import {isAxiosError, type AxiosError} from 'axios';

import type {RefObject} from 'react';
import type {SuccessCallback, ErrorCallback} from '@/redux/actions/typings';

interface UseSubmitEmailHookProps {
  inputRef: RefObject<HTMLInputElement>;
  onSubmit: (params: {
    email: string;
    onSuccess?: SuccessCallback;
    onError?: ErrorCallback;
  }) => void | Promise<void>;
}

/**
 * @description extracting the error messages from the API call,
 * which possibly could be the Axios error bearing the error response from API
 * @param {unknown} error the error thrown by the API call
 * @return {string[]} - The user friendly error messages
 */
export const getErrorMessages = (error: unknown): string[] => {
  if (isAxiosError(error)) {
    const axiosError = error as AxiosError<{email?: string[]}>;
    const messagesList = axiosError.response?.data.email || [
      'An error occurred. Please try again later.',
    ];

    return messagesList;
  }

  return ['An error occurred. Please try again later.'];
};

/**
 * @description Function to validate the email address
 * @param {string} email the email address to be validated
 * @return {boolean} - The result of the validation
 */
export const isEmailValid = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

/**
 * @description Function to update the state with new error messages;
 * in case of no duplicates, it will add the new error message to the state;
 * @param {string[]} current current state
 * @param {string} newError incoming error message
 * @return {string[]} - The new state in immutability
 */
export const updateStateNoDuplicates = (current: string[], newError: string): string[] => {
  const newErrorsList = [...current, newError];
  return [...new Set(newErrorsList)];
};

/**
 * @description Custom hook to handle the form submission of the email,
 * the form is using the text input as an uncontrolled component.
 * @param {UseSubmitEmailHookProps} - object: input element ref and function that makes the api call
 * @return {{status, errors, submitForm}} - The status, errors and handler of the form submission
 */
export const useSubmitEmail = ({inputRef, onSubmit}: UseSubmitEmailHookProps) => {
  const [status, setStatus] = useState<'idle' | 'loading' | 'success'>('idle');
  const [errors, setErrors] = useState<string[]>([]);

  /**
   * @description Function to handle the success of the form submission,
   * API responds with the email and a boolean flag indicates if the email exists
   * Status 201 'Created' {email: 'abc@gmail.com', exists: false}
   * Status 201 'Created' {email: 'abc@gmail.com', exists: true}
   * If the email exists, it will show an error message
   * @param {{email:string, exists:boolean}} data the response data from the API
   * @return {undefined} the effect procedure function
   */
  const onSuccess = (data: {email: string; exists: boolean}) => {
    if (data.exists) {
      setErrors((current) =>
        updateStateNoDuplicates(current, 'You are already subscribed to our newsletter.'),
      );
      setStatus('idle');
    } else {
      setStatus('success');
      inputRef.current?.value && (inputRef.current.value = '');
    }
  };

  const onError = (error: unknown) => {
    const errorMessages = getErrorMessages(error);
    setErrors(errorMessages);
    setStatus('idle');
  };

  const submitForm = async () => {
    const email = inputRef.current?.value;
    if (!email || !isEmailValid(email)) {
      setErrors((current) =>
        updateStateNoDuplicates(current, 'Please enter a valid email address.'),
      );
      return;
    }

    setStatus('loading');
    setErrors([]);

    onSubmit({email, onSuccess, onError});
  };

  const handleReset = () => {
    setStatus('idle');
    setErrors([]);
    if (inputRef.current?.value) {
      inputRef.current.value = '';
    }
  };

  return {status, errors, handleReset, submitForm};
};
