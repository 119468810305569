import axios from 'axios';

import type {ArtistCardProps} from '@/molecules/Cards/Artist/typings';
import type {ReduxAsyncAction} from './typings';

import constantsFactory from '@/utils/constants';
import paramsHelper from '@/utils/paramsHelper';
import {
  LOAD_ARTISTS_DISCOVER_LIST_SUCCESS,
  LOAD_ARTISTS_DISCOVER_LIST_REQUEST,
  LOAD_ARTISTS_DISCOVER_LIST_FAILURE,
  LOAD_ARTISTS_POPULAR_LIST_REQUEST,
  LOAD_ARTISTS_POPULAR_LIST_SUCCESS,
  LOAD_ARTISTS_POPULAR_LIST_FAILURE,
} from '@/redux/actionTypes';

const {API} = constantsFactory();

export interface SuccessApiResponseBody {
  count: number;
  // eslint-disable-next-line camelcase
  num_pages: number;
  results: ArtistCardProps[];
}

interface ArtistsParams {
  limit?: number;
}

interface ArtistsDiscoverParams extends ArtistsParams {
  country?: string;
}

const callArtistsAPI = (query: string) =>
  axios.get<SuccessApiResponseBody>(`${process.env.HOST}${API.ARTISTS}${query}`, {
    headers: {Accept: 'application/json', 'Content-Type': 'application/json'},
  });

export const getArtistDiscoverCards: ReduxAsyncAction<
  ArtistsDiscoverParams,
  SuccessApiResponseBody
> = ({limit = 8}, onSuccess = [], onError = [], onCleanup = []) => ({
  types: [
    LOAD_ARTISTS_DISCOVER_LIST_REQUEST,
    LOAD_ARTISTS_DISCOVER_LIST_SUCCESS,
    LOAD_ARTISTS_DISCOVER_LIST_FAILURE,
  ],
  callAPI() {
    const query = paramsHelper({type: 'discover', limit}).objectToQueryString();
    return callArtistsAPI(query);
  },
  onSuccess,
  onCleanup,
  onError,
});

export const getArtistPopularCards: ReduxAsyncAction<ArtistsParams, SuccessApiResponseBody> = (
  {limit = 4},
  onSuccess = [],
  onError = [],
  onCleanup = [],
) => ({
  types: [
    LOAD_ARTISTS_POPULAR_LIST_REQUEST,
    LOAD_ARTISTS_POPULAR_LIST_SUCCESS,
    LOAD_ARTISTS_POPULAR_LIST_FAILURE,
  ],
  callAPI() {
    const query = paramsHelper({type: 'popular', limit}).objectToQueryString();
    return callArtistsAPI(query);
  },
  onSuccess,
  onCleanup,
  onError,
});
