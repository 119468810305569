import dynamic from 'next/dynamic';
import Head from 'next/head';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import type {ErrorCallback, SuccessCallback} from '@/redux/actions/typings';
import type {GetServerSideProps, NextPage} from 'next';
import type {
  ArtistCardListProps,
  EditorsPicksListProps,
  JointLayoutProps,
  PageProps,
} from './typings';

import Image from '@/atoms/Image/Image';
import OutlinedLink from '@/atoms/Links/Outlined/OutlinedLink';
import TextLink from '@/atoms/Links/Text/TextLink';
import ArtistCardSkeleton from '@/atoms/Skeletons/ArtistCard/ArtistCardSkeleton';
import ContentCardSkeleton from '@/atoms/Skeletons/ContentCard/ContentCardSkeleton';
import TrustPilot from '@/atoms/TrustPilot/TrustPilot';
import useAbTest from '@/hooks/use-ab-test/use-ab-test';
import useAbTestData from '@/hooks/use-ab-test/use-ab-test-data';
import useAuth from '@/hooks/use-auth/use-auth';
import HomePageBanner from '@/molecules/Banners/HomePage/HomePageBanner';
import ArtistCard from '@/molecules/Cards/Artist/ArtistCard';
import ContentCard from '@/molecules/Cards/Content/ContentCard';
import ProductCard from '@/molecules/Cards/Product/ProductCard';
import JoinMailingListForm from '@/molecules/Fragments/Forms/JoinMailingList/JoinMailingListForm';
import RecentlyViewedSection from '@/molecules/Fragments/Sections/RecentlyViewed/RecentlyViewedSection';
import Search from '@/molecules/Search/Search';
import CustomerSupportUsp, {
  StyledCardsList,
} from '@/molecules/USPs/CustomerSupport/CustomerSupportUsp';
import SmallUsp from '@/molecules/USPs/small/SmallUsp';
import PageLayout from '@/organisms/Layout/Page/PageLayout';
import {getArtistDiscoverCards, getArtistPopularCards} from '@/redux/actions/artistsListActions';
import {getEditorsPicksList} from '@/redux/actions/editorsPicksActions';
import {joinMailingList} from '@/redux/actions/joinMailingListActions';
import {loadProductFavourites} from '@/redux/actions/productFavouriteActions';
import {getArtistSearchAutocomplete} from '@/redux/actions/searchActions';
import {STATUS_LOADING} from '@/redux/actionTypes';
import {selectArtistsDiscover} from '@/redux/reducers/artistsDiscoverReducer';
import {selectArtistsPopular} from '@/redux/reducers/artistsPopularReducer';
import {selectEditorsPicks} from '@/redux/reducers/editorsPicksReducer';
import {selectUserSettingsData} from '@/redux/reducers/userSettingsReducer';
import {getFeaturedArtists} from '@/ssr/artist/getFeaturedArtists';
import {getEditorPicks} from '@/ssr/collections/getEditorPicks';
import {getFeaturedArtworks} from '@/ssr/product/getFeaturedArtworks';
import colors from '@/theme/constants/colors';
import constantsFactory from '@/utils/constants';
import {
  pushGAClickArtistCardEvent,
  pushGAClickArtistSearchResult,
  pushGAClickBottomUSPEvent,
  pushGAClickEditorsPicksEvent,
  pushGAJoinMailingListEvent,
  pushGAPopularSearchesEvent,
  pushGAShopByCategoryEvent,
  pushGAShopByPriceEvent,
} from '@/utils/gtm';
import {shuffle} from '@/utils/helpers/array';
import {
  brandContent,
  customerReviewsContent,
  customerSupportUspContent,
  discoverArtistsContent,
  editorsPicksContent,
  mostPopularSearches,
  shopByCategoryContent,
  shopByPriceContent,
  topUspContent,
} from '@/utils/homepage/content';
import {getMetadata} from '@/utils/homepage/metadata';
import Bugsnag from '@bugsnag/js';
import {withRedirects} from '../../lib/withRedirects';
import {StyledColorBlock} from '../styles';
import {
  StyledABTestingContainer,
  StyledBrandList,
  StyledBrandListItem,
  StyledBrandSection,
  StyledCardGridList,
  StyledCustomerReviewsContainer,
  StyledDiscoverArtistContainer,
  StyledHeader,
  StyledHomepage,
  StyledJoinMailingListContainer,
  StyledJointSectionsLayout,
  StyledOutlinedLinksList,
  StyledShopByPriceList,
  StyledTopUspContainer,
} from './styles';
const InfoIconWithNoSSR = dynamic(() => import('@/molecules/InfoIcon/InfoIcon'), {ssr: false});

const {DATA_TEST_ID, CACHE} = constantsFactory();

const ListLoadingSkeleton = ({
  skeleton,
  skeletonCount = 4,
}: {
  skeleton: JSX.Element;
  skeletonCount?: number;
}) => (
  <>
    <li className="sr-only">Loading items</li>

    {Array.from({length: skeletonCount}).map((_, i) => (
      <li key={i}>{skeleton}</li>
    ))}
  </>
);

const ArtistCardList = ({
  list,
  isLoading,
  error,
  sectionName,
  skeletonCount = 4,
  followBtn,
}: ArtistCardListProps): JSX.Element | null => {
  if (isLoading) {
    return (
      <ListLoadingSkeleton
        skeleton={<ArtistCardSkeleton followBtn={followBtn} />}
        skeletonCount={skeletonCount}
      />
    );
  }
  if (error || !list) {
    return null;
  }

  return (
    <>
      {list.map((artist, index) => (
        <li key={artist.id} aria-label={artist.name}>
          <ArtistCard
            artist={artist}
            key={`${sectionName}-${artist.id}`}
            followBtn={followBtn}
            onClick={() => {
              pushGAClickArtistCardEvent(artist.slug, index + 1, sectionName);
            }}
          />
        </li>
      ))}
    </>
  );
};

const JointLayout = ({shouldRender, children}: JointLayoutProps): JSX.Element | null => {
  if (!shouldRender) return null;

  return (
    <StyledJointSectionsLayout
      data-testid={DATA_TEST_ID.HOME_PAGE_STYLED_LAYOUT_ARTISTS_SECTIONS}
      $overflow
    >
      {children}
    </StyledJointSectionsLayout>
  );
};

const EditorPicksList = ({list, isLoading, error}: EditorsPicksListProps): JSX.Element | null => {
  if (isLoading) return <ListLoadingSkeleton skeleton={<ContentCardSkeleton />} />;

  if (error || !list) {
    return null;
  }

  return (
    <>
      {list.map((editorPickItem, index) => (
        <li key={editorPickItem.slug} aria-label={editorPickItem.name}>
          <ContentCard
            img={editorPickItem.images?.[0]?.path}
            title={editorPickItem.name}
            href={`/staff-picks/${editorPickItem.slug}`}
            onClick={() => {
              pushGAClickEditorsPicksEvent(editorPickItem.name, editorPickItem.slug, index + 1);
            }}
          />
        </li>
      ))}
    </>
  );
};

const Homepage: NextPage<PageProps> = ({editorsPicks: editorsPicksSSR, featuredArtists}) => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const popularArtists = useSelector(selectArtistsPopular);
  const discoverArtists = useSelector(selectArtistsDiscover);
  const editorsPicks = useSelector(selectEditorsPicks);
  const userSettings = useSelector(selectUserSettingsData);
  const [isTrustPilotError, setIsTrustPilotError] = useState<boolean>(false);

  const abTest = useAbTest<'a' | 'b'>('hpt');
  const {data: featuredArtworks} = useAbTestData({
    enabled: abTest === 'a' || abTest === 'b',
    asyncFn: getFeaturedArtworks,
  });

  const metadata = getMetadata();
  const isAuthenticated = auth.isAuthenticated();

  const onSubmitMail = ({
    email,
    onSuccess,
    onError,
  }: {
    email: string;
    onSuccess: SuccessCallback;
    onError: ErrorCallback;
  }) => {
    const onSuccessFireGtmEvent = (data: {email: string; exists: boolean}) => {
      if (data.exists === false) {
        pushGAJoinMailingListEvent('Homepage');
      }
    };

    dispatch(joinMailingList(email, [onSuccess, onSuccessFireGtmEvent], [onError]));
  };

  /**
   * @description Fetch on page mount:
   * - editors picks
   * - popular artists
   * - discover artists around the world
   */
  useEffect(() => {
    dispatch(getEditorsPicksList({limit: 4}));
    dispatch(getArtistPopularCards({limit: 4}));
    dispatch(getArtistDiscoverCards({limit: 8}));
  }, []);

  /**
   * @description Fetch favourites:
   * - if user is logged in
   * - and if the AB test is active
   */
  useEffect(() => {
    if (!!abTest && isAuthenticated) {
      dispatch(loadProductFavourites());
    }
  }, [abTest, isAuthenticated]);

  return (
    <StyledHomepage data-testid={DATA_TEST_ID.HOME_PAGE_STYLED}>
      <Head>
        <title>{metadata.title}</title>
        {metadata.metaTags.map(({id, ...metaProps}) => (
          <meta key={id} {...metaProps} />
        ))}
        {metadata.links.map(({id, ...linkProps}) => (
          <link key={id} {...linkProps} />
        ))}
      </Head>

      <PageLayout editorsPicks={editorsPicksSSR}>
        <main>
          <HomePageBanner country={userSettings.country} />

          <StyledABTestingContainer
            $abTest={abTest}
            $featuredArtworksLoaded={!!featuredArtworks?.length}
          >
            <StyledColorBlock $verticalPadding="m">
              <h1>Shop original art from independent artists around the world.</h1>

              <StyledTopUspContainer aria-label={'Why choose Artfinder?'}>
                {topUspContent.map((usp, i) => (
                  <SmallUsp key={i} icon={usp.icon} background={usp.background}>
                    {usp.children}
                  </SmallUsp>
                ))}
              </StyledTopUspContainer>
            </StyledColorBlock>

            {!!featuredArtworks?.length && !!abTest && (
              <StyledColorBlock $background={colors.grey25}>
                <StyledHeader data-convert-id="featured-art-header">
                  <h2>Featured art</h2>

                  <TextLink
                    href={'/art/'}
                    title="View all"
                    onClick={() => {
                      pushGAShopByCategoryEvent('View all', 'art');
                    }}
                  >
                    View all
                  </TextLink>
                </StyledHeader>

                <StyledCardGridList data-convert-id="featured-art">
                  {featuredArtworks.map((item) => (
                    <li key={item.id}>
                      <ProductCard
                        product={item}
                        displaySizeUnits={userSettings.display_units}
                        displayCurrency={userSettings.currency}
                        currencySymbol={userSettings.currency_symbol}
                        lazyLoading={false}
                        respectAspectRatio={false}
                      />
                    </li>
                  ))}
                </StyledCardGridList>
              </StyledColorBlock>
            )}
          </StyledABTestingContainer>

          <StyledColorBlock
            $background={colors.grey25}
            $verticalPadding="m"
            aria-label={mostPopularSearches(userSettings.currency_symbol).title}
          >
            <h2 className="sr-only">{mostPopularSearches(userSettings.currency_symbol).title}</h2>

            <StyledOutlinedLinksList $desktopJustify="center" $noHeader>
              {mostPopularSearches(userSettings.currency_symbol).list.map((searchLink) => (
                <li key={`most-popular-links-${searchLink.id}`}>
                  <OutlinedLink
                    href={searchLink.link}
                    onClick={() => {
                      pushGAPopularSearchesEvent(searchLink.label, searchLink.slug);
                    }}
                  >
                    {searchLink.label}
                  </OutlinedLink>
                </li>
              ))}
            </StyledOutlinedLinksList>
          </StyledColorBlock>

          {!!featuredArtists?.length && (
            <StyledColorBlock $overflow>
              <StyledHeader>
                <h2>Featured artists</h2>

                <InfoIconWithNoSSR
                  text={'Featured artists section includes sponsored listings'}
                  color={colors.darkBlue25}
                />
              </StyledHeader>

              <StyledCardGridList $desktopRows={2}>
                <ArtistCardList
                  list={featuredArtists}
                  error={!featuredArtists?.length ? `Couldn't fetch featured artists` : null}
                  isLoading={false}
                  sectionName="featured"
                />
              </StyledCardGridList>
            </StyledColorBlock>
          )}

          <StyledColorBlock
            $background={colors.grey25}
            $verticalPadding="m"
            aria-describedby="shop-by-category-helper"
            aria-label="shop by category section"
          >
            <span id="shop-by-category-helper" className="sr-only">
              {metadata.regionDescription.shopByCategory}
            </span>

            <StyledHeader>
              <h2>{shopByCategoryContent.title}</h2>

              <TextLink
                href={shopByCategoryContent.links.viewAllLinkHref}
                title="View all shopping categories"
                onClick={() => {
                  pushGAShopByCategoryEvent('View all', 'art');
                }}
              >
                View all
              </TextLink>
            </StyledHeader>

            <StyledOutlinedLinksList aria-label="shop by category list of links">
              {shopByCategoryContent.links.categories.map(({id, linkHref, linkText, slug}) => (
                <li key={id} aria-label={linkText}>
                  <OutlinedLink
                    href={linkHref}
                    onClick={() => {
                      pushGAShopByCategoryEvent(linkText, slug);
                    }}
                  >
                    {linkText}
                  </OutlinedLink>
                </li>
              ))}
            </StyledOutlinedLinksList>
          </StyledColorBlock>

          {!editorsPicks.error && (
            <StyledColorBlock
              aria-describedby="editors-picks-helper"
              aria-label="editors picks section"
            >
              <span id="editors-picks-helper" className="sr-only">
                {metadata.regionDescription.editorsPicks}
              </span>
              <StyledHeader>
                <h2>{editorsPicksContent.title}</h2>
                <TextLink
                  href={editorsPicksContent.viewAllLinkHref}
                  title="View all editors picks"
                  onClick={() => {
                    pushGAClickEditorsPicksEvent('View all', editorsPicksContent.viewAllLinkHref);
                  }}
                >
                  View all
                </TextLink>
              </StyledHeader>
              <StyledCardGridList>
                <EditorPicksList
                  list={editorsPicks.data.length > 0 ? editorsPicks.data : null}
                  isLoading={editorsPicks.status === STATUS_LOADING}
                  error={editorsPicks.error}
                />
              </StyledCardGridList>
            </StyledColorBlock>
          )}

          <StyledColorBlock
            $background={colors.grey25}
            $verticalPadding="m"
            aria-describedby="shop-by-price-helper"
            aria-label="shop by price section"
          >
            <span id="shop-by-price-helper" className="sr-only">
              {metadata.regionDescription.shopByPrice}
            </span>

            <StyledHeader>
              <h2>{shopByPriceContent(userSettings.currency_symbol).title}</h2>
            </StyledHeader>

            <StyledShopByPriceList aria-label="shop by price range list of links">
              {shopByPriceContent(userSettings.currency_symbol).links.map(
                ({id, linkText, linkHref, slug}) => (
                  <li key={id} aria-label={linkText}>
                    <OutlinedLink
                      href={linkHref}
                      onClick={() => {
                        pushGAShopByPriceEvent(linkText, slug);
                      }}
                    >
                      {linkText}
                    </OutlinedLink>
                  </li>
                ),
              )}
            </StyledShopByPriceList>
          </StyledColorBlock>

          <JointLayout shouldRender={!popularArtists.error || !discoverArtists.error}>
            {!popularArtists.error && (
              <section
                aria-describedby="popular-artists-helper"
                aria-label="popular artists section"
              >
                <span id="popular-artists-helper" className="sr-only">
                  {metadata.regionDescription.popularArtists}
                </span>
                <StyledHeader>
                  <h2>Popular artists</h2>
                </StyledHeader>
                <StyledCardGridList>
                  <ArtistCardList
                    list={popularArtists.data.length > 0 ? popularArtists.data : null}
                    isLoading={popularArtists.status === STATUS_LOADING}
                    error={popularArtists.error}
                    followBtn
                    sectionName="popular"
                  />
                </StyledCardGridList>
              </section>
            )}

            {!discoverArtists.error && (
              <section
                aria-describedby="discover-artists-helper"
                aria-label="discover artists around the world section"
              >
                <span id="discover-artists-helper" className="sr-only">
                  {metadata.regionDescription.discoverArtists}
                </span>
                <StyledDiscoverArtistContainer>
                  <StyledHeader>
                    <h2>{discoverArtistsContent.title}</h2>

                    <p>{discoverArtistsContent.subTitle}</p>

                    <Search
                      placeholder="Search artists..."
                      searchAction={getArtistSearchAutocomplete}
                      topLinkTarget="artist-search"
                      gaEvent={pushGAClickArtistSearchResult}
                    />
                  </StyledHeader>

                  <StyledCardGridList $desktopRows={2}>
                    <ArtistCardList
                      list={discoverArtists.data.length > 0 ? discoverArtists.data : null}
                      isLoading={discoverArtists.status === STATUS_LOADING}
                      error={discoverArtists.error}
                      sectionName="discover"
                      followBtn
                      skeletonCount={8}
                    />
                  </StyledCardGridList>
                </StyledDiscoverArtistContainer>
              </section>
            )}
          </JointLayout>

          <StyledColorBlock
            $background={colors.grey25}
            aria-describedby="usp-bottom"
            aria-label="customer support section"
          >
            <h2 className="sr-only">{customerSupportUspContent.title}</h2>

            <span id="usp-bottom" className="sr-only">
              {metadata.regionDescription.uspBottom}
            </span>

            <StyledCardsList aria-label="customer support services list">
              {customerSupportUspContent.cards.map((card) => (
                <CustomerSupportUsp
                  key={card.title}
                  aria-label={card.title}
                  onClick={() => pushGAClickBottomUSPEvent(card.title)}
                  {...card}
                />
              ))}
            </StyledCardsList>
          </StyledColorBlock>

          {!isTrustPilotError && (
            <StyledColorBlock
              aria-describedby="customer-reviews-helper"
              aria-label="customer reviews section"
            >
              <span id="customer-reviews-helper" className="sr-only">
                {metadata.regionDescription.customerReviews}
              </span>
              <StyledCustomerReviewsContainer>
                <StyledHeader>
                  <h2>{customerReviewsContent.title}</h2>
                </StyledHeader>
                <TrustPilot onError={(errorMessage) => setIsTrustPilotError(!!errorMessage)} />
              </StyledCustomerReviewsContainer>
            </StyledColorBlock>
          )}

          <RecentlyViewedSection />

          <StyledColorBlock
            aria-describedby="join-mailing-list-helper"
            aria-label="join mailing list section"
            $background={colors.green100}
            $verticalPadding="m"
          >
            <span id="join-mailing-list-helper" className="sr-only">
              {metadata.regionDescription.joinMailingList}
            </span>
            <StyledJoinMailingListContainer>
              <h2>Join our mailing list</h2>
              <JoinMailingListForm onSubmit={onSubmitMail} />
            </StyledJoinMailingListContainer>
          </StyledColorBlock>

          <StyledBrandSection
            $verticalPadding="m"
            data-testid={DATA_TEST_ID.HOME_PAGE_BRAND_SECTION}
          >
            <h2 className="sr-only">{brandContent.title}</h2>

            <StyledBrandList>
              {brandContent.images.map(({src, id, width, height, alt}) => (
                <StyledBrandListItem key={id} $id={id}>
                  <Image path={src} alt={alt} width={width} height={height} />
                </StyledBrandListItem>
              ))}
            </StyledBrandList>
          </StyledBrandSection>
        </main>
      </PageLayout>
    </StyledHomepage>
  );
};

export default Homepage;

export const getServerSideProps: GetServerSideProps<PageProps> = withRedirects(async ({res}) => {
  try {
    const editorsPicks = await getEditorPicks();
    let featuredArtists = await getFeaturedArtists();
    if (featuredArtists?.length) featuredArtists = shuffle(featuredArtists);

    /**
     * @description Set cache headers, to cache the homepage for 30 minutes
     */
    if (res) {
      res.setHeader(
        'Cache-Control',
        `public, s-maxage=${CACHE.HOMEPAGE_CACHE_TTL_SECONDS}, stale-while-revalidate=${CACHE.HOMEPAGE_CACHE_STALE_WHILE_REVALIDATE_TTL_SECONDS}`,
      );
    }

    return {
      props: {
        editorsPicks,
        featuredArtists,
      },
    };
  } catch (error) {
    console.log(`[Homepage] Error. Invalid Rendering - ${error}`);
    Bugsnag.notify(new Error(`[Homepage] Error. Invalid Rendering - ${error}`), (event) => {
      event.severity = 'error';
      event.unhandled = true;
    });

    return {
      redirect: {
        destination: `${process.env.HOST}/404/`,
        permanent: false,
        basePath: false,
      },
    };
  }
});
