import {useEffect, useState} from 'react';

const getQueryParamValue = <V extends string>(queryParamName: string): V | null => {
  if (typeof window === 'undefined') return null;
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(queryParamName) as V | null;
};

/**
 * @description hook to get the value of an A/B test query parameter
 * that can be set in the URL as a query parameter.
 * @example
 * const abTest = useAbTest<'a'| 'b'>('hpt');
 * if (abTest === null) {
 *    console.log('No A/B test');
 * } else if (abTest === 'a') {
 *    console.log('A/B test A');
 * } else if (abTest === 'b') {
 *    console.log('A/B test B');
 * }
 *
 * @param {string} queryParamName expected A/B test query parameter name
 * @return {string | null} A/B test query parameter value or null if not present
 */
const useAbTest = <Q extends string>(queryParamName: string) => {
  const [abTest, setAbTest] = useState<Q | null>(null);

  useEffect(() => {
    const handlePopState = () => {
      setAbTest(getQueryParamValue<Q>(queryParamName));
    };

    /**
     * On mount, set initial value of A/B test query parameter
     */
    setAbTest(getQueryParamValue<Q>(queryParamName));

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [queryParamName]);

  return abTest;
};

export default useAbTest;
