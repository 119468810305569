'use client';

import {useEffect, useRef, useState} from 'react';

import type Props from './typings';
import type {FetchStatus} from './typings';

import constantsFactory from '@/utils/constants';
import {retryIfFails} from '@/utils/helpers/api';
import {StyledTrustPilotContainer} from './styles';

const {DATA_TEST_ID, TRUSTPILOT_TEMPLATE} = constantsFactory();

const widgetType = {
  carousel: {
    templateId: TRUSTPILOT_TEMPLATE.CAROUSEL,
    height: '140px',
  },
  microStar: {
    templateId: TRUSTPILOT_TEMPLATE.MICRO_STAR,
    height: '25px',
  },
} as const;

/**
 * @description loads Trustpilot widget markup in to provided div container
 * the `window.Trustpilot.loadFromElement` function is provided by Trustpilot API is synchronous
 * and in order to make the code run in a non-blocking way, we wrap it in a promise
 * Additionally: it has been reported that occasionally the Trustpilot widget does not load on the first attempt
 * @param {HTMLElement} element container element ( div ) for Trustpilot widget
 * @return {Promise<true>} resolves to `true` if Trustpilot widget is loaded
 */
export const loadTrustPilotMarkup = (element: HTMLElement | null): Promise<true> =>
  new Promise((resolve, reject) => {
    if (!element) {
      reject(new Error('Trustpilot widget container not available'));
    } else if (!window.Trustpilot) {
      reject(new Error('Trustpilot API not available'));
    } else {
      window.Trustpilot.loadFromElement(element, true);
      resolve(true);
    }
  });

/**
 * @description TrustPilot widget component,
 * on mount it initializes the TrustPilot API and loads the reviews into provided `div` container
 * can take optional callback for error to inform the parent component on it's status,
 * - on error it will invoke `onError` callback with the error message
 * - on success it will invoke `onError` callback as well with the `null` value
 * @example
 * <TrustPilot
 *  onError={(error:null|string) => {
 *    if (error) {
 *      console.error(error? `Error message: ${error}`);
 *    } else {
 *      console.log('TrustPilot reviews loaded successfully. Error has been reset.');
 *    }
 *  }}
 * />
 * @return {JSX.Element | null} TrustPilot reviews or `null` if there is an error
 */
const TrustPilot = ({
  onError,
  width = '100%',
  locale = 'en-GB',
  theme = 'light',
  stars = 5,
  type = 'carousel',
}: Props): JSX.Element | null => {
  const [status, setStatus] = useState<FetchStatus<boolean>>({
    data: false,
    isLoading: true,
    error: null,
  });
  const ref = useRef<HTMLDivElement>(null);

  /**
   * @description initialize and load Trustpilot widget on component mount
   */
  useEffect(() => {
    let isMounted = true;

    /**
     * @description loads Trustpilot widget
     * and will retry 1 more time if it fails to load
     */
    const loadTrustPilotWidget = async () => {
      try {
        await retryIfFails(() => loadTrustPilotMarkup(ref.current));

        if (isMounted) {
          onError && onError(null);
          setStatus({
            data: true,
            isLoading: false,
            error: null,
          });
        }
      } catch (err) {
        if (isMounted) {
          const errorMessage =
            err instanceof Error
              ? err.message
              : err.toString() || 'Trustpilot API initialization failed';

          onError && onError(errorMessage);
          setStatus({
            data: false,
            isLoading: false,
            error: errorMessage,
          });
        }
      }
    };

    loadTrustPilotWidget();

    /**
     * @description cleanup on unmount
     */
    return () => {
      isMounted = false;
    };
  }, []);

  return status.error ? null : (
    <StyledTrustPilotContainer
      ref={ref}
      data-testid={DATA_TEST_ID.TRUSTPILOT_WIDGET_CONTAINER}
      data-template-id={widgetType[type].templateId}
      data-businessunit-id={process.env.NEXT_PUBLIC_TRUSTPILOT_BUSINESS_UNIT_ID}
      data-style-height={widgetType[type].height}
      data-style-width={width}
      data-locale={locale}
      data-theme={theme}
      data-stars={`${stars}`}
      $type={type}
    >
      <a
        href={`https://www.trustpilot.com/review/${process.env.NEXT_PUBLIC_TRUSTPILOT_BUSINESS_UNIT_ID}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </StyledTrustPilotContainer>
  );
};

export default TrustPilot;
