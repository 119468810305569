import axios from 'axios';
import constantsFactory from '@/utils/constants';
import {
  LOAD_JOIN_MAILING_LIST_REQUEST,
  LOAD_JOIN_MAILING_LIST_SUCCESS,
  LOAD_JOIN_MAILING_LIST_FAILURE,
} from '@/redux/actionTypes';
import {ReduxAsyncAction} from './typings';

const {API} = constantsFactory();

export const joinMailingList: ReduxAsyncAction<
  string,
  {
    email: string;
    exists: boolean;
  }
> = (email: string, onSuccess = [], onError = [], onCleanup = []) => ({
  types: [
    LOAD_JOIN_MAILING_LIST_REQUEST,
    LOAD_JOIN_MAILING_LIST_SUCCESS,
    LOAD_JOIN_MAILING_LIST_FAILURE,
  ],
  callAPI: () =>
    axios.post<{email: 'string'; exists: boolean}>(
      `${process.env.HOST}${API.JOIN_MAILING_LIST}`,
      {email},
      {
        headers: {Accept: 'application/json', 'Content-Type': 'application/json'},
      },
    ),
  onSuccess,
  onCleanup,
  onError,
});
